import { getPlanDetailPage, getPlanTaskStatusStatistic, getPlanTaskTypeStatistic, getAmountStat, getPlanPage, getTaskAdStatistic, getAdTasktypeStatistic, getAdAmountStat } from '@/api/msp/plan'

const getDefaultState = () => {
  return {
    taskInfo: [],
    searchQuery: {},
    curDomainId: -1,
    planDetailQuery: {
      pageNumber: 1,
      pageSize: 15,
      domainId: null,
      actionDate: '',
      taskitemType: null,
      qualityTesting: null,
      orderId: null,
      startDate: '',
      endDate: ''
    },
    planDetailData: {},
    showType: 1, // 展示方式，1按作业日期，2按客户品牌
    statusArray: [
      { value: 0, name: '未完成' },
      { value: 1, name: '已完成未质检' },
      { value: 2, name: '已质检未对账' },
      { value: 3, name: '已对账' }
    ], // 筛选条件状态集合
    taskStatusList: [], // 右侧任务状态统计数据
    taskTypeArray: [], // 任务类型集合数据
    taskTypeList: [], // 右侧任务类型统计数据
    taskAmountStatData: {},
    leftPage: 'LIST',
    planData: {},
    curPlanKey: '', // 当前选中的作业key，格式domainId_actionDate
    curPlanIndex: '' // 当前选中得作业索引，格式pageNumber_index
  }
}
const state = getDefaultState()
const qualityTesting = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    reset_plan_detail_query (state) {
      state.planDetailQuery = getDefaultState().planDetailQuery
    },
    set_task_info (state, data) {
      state.taskInfo = data
    },
    set_search_query (state, data) {
      state.searchQuery = data
    },
    set_show_type (state, data) {
      state.showType = data
    },
    set_plan_detail_query (state, data) {
      state.planDetailQuery = data
    },
    set_plan_detail_data (state, data) {
      state.planDetailData = data
    },
    set_task_status_list (state, data) {
      state.taskStatusList = data
    },
    set_task_type_list (state, data) {
      state.taskTypeList = data || []
    },
    set_task_type_array (state, data) {
      state.taskTypeArray = data || []
    },
    set_task_amount_stat_list (state, data) {
      state.taskAmountStatData = data
    },
    set_cur_domainId (state, data) {
      state.curDomainId = data
    },
    set_left_page (state, data) {
      state.leftPage = data
    },
    set_plan_data (state, data) {
      state.planData = data
    },
    set_cur_plan_key (state, data) {
      state.curPlanKey = data
    },
    set_cur_plan_index (state, data) {
      state.curPlanIndex = data
    }
  },
  actions: {
    getPlanData ({ state, commit }, query) {
      const newQuery = Object.assign(state.searchQuery, query)
      commit('set_search_query', newQuery)
      return new Promise((resolve) => {
        getPlanPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_plan_data', res)
          } else {
            commit('set_plan_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 分页获取质检详细数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getPlanDetailData ({ state, commit }, query) {
      const newQuery = Object.assign(state.planDetailQuery, query)
      commit('set_plan_detail_query', newQuery)
      return new Promise((resolve) => {
        getPlanDetailPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_plan_detail_data', res)
          } else {
            commit('set_plan_detail_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取工单状态统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getTaskStatusStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getPlanTaskStatusStatistic(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_status_list', res)
          } else {
            commit('set_task_status_list', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取工单任务类型统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getTaskTypeStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getPlanTaskTypeStatistic(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_type_list', res)
          } else {
            commit('set_task_type_list', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取工单任务费用统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getTaskAmountStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getAmountStat(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_amount_stat_list', res)
          } else {
            commit('set_task_amount_stat_list', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取客户品牌状态统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getAdTaskStatusStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getTaskAdStatistic(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_status_list', res)
          } else {
            commit('set_task_status_list', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取客户品牌任务类型统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getAdTaskTypeStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getAdTasktypeStatistic(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_type_list', res)
          } else {
            commit('set_task_type_list', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取客户品牌任务费用统计数据
     * @param {Object} param0
     * @param {Object} query
     * @returns
     */
    getAdTaskAmountStatData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getAdAmountStat(query).then(res => {
          if (res && !res.errcode) {
            commit('set_task_amount_stat_list', res)
          } else {
            commit('set_task_amount_stat_list', [])
          }
          resolve(res)
        })
      })
    }
  }
}

export default qualityTesting
