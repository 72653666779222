<template>
  <div>
    <container>
      <div slot="left_box_content">
        <!-- <left @on-change-item="handleChangeItem"/> -->
        <component
          :is="curLeftComponent"
          ref="plan-left"
        ></component>
      </div>
      <div slot="bottom_box_content">
        <bottom ref="plan-bottom" />
      </div>
      <div slot="right_box_content">
        <right ref="plan-right"></right>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// import left from './components/Left'
import bottom from './components/Bottom'
import right from './components/Right'
// 引入store模块
import qualityTestingModule from '@/store/modules/qualityTesting'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    bottom,
    right,
    DefaultLeft: () => import('./components/Left'),
    SetFiles: () => import('./components/SetFiles')
  },
  computed: {

  },
  created () {
    if (!this.$store.hasModule('qualityTesting')) {
      this.$store.registerModule('qualityTesting', qualityTestingModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setLeftComponent('DefaultLeft')
    this.setShowRightBlock(true)
    this.setBottomHeight('90%')
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('qualityTesting')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  },
  methods: {
    // handleLoadRightData (planItem) {
    //   // this.$refs['plan-right'].loadPlanStatistic(planItem)
    //   if (this.curLeftComponent === 'DefaultLeft') {
    //     this.$refs['plan-left'].initData()
    //   }
    // }
  }
}
</script>
